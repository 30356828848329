import request from '@/utils/request'

const exhibition_orderApi = {
  exhibition_order_list: '/exhibition/order/',
  exhibition_order_create: '/exhibition/order/',
  exhibition_order_update: '/exhibition/order/',
  exhibition_order_delete: '/exhibition/order/',
  exhibition_order_commodity_list: '/exhibition/order/commodity/',
  exhibition_order_normal_commodity_list: '/exhibition/order/normal_commodity/',
  exhibition_order_record_list: '/exhibition/order/',
  exhibition_order_commodity_cash_refund_list: '/exhibition/order/commodity/cash_refund/',
  exhibition_order_commodity_refund_list: '/exhibition/order/commodity/refund/',
  exhibition_order_record_refund_list: '/exhibition/order/record/refund/',
  exhibition_order_normal_commodity_cash_refund_list: '/exhibition/order/normal_commodity/cash_refund/',
  exhibition_order_normal_commodity_refund_list: '/exhibition/order/normal_commodity/refund/'
}

/**
 * 列表
 */
export function exhibition_order_normal_commodity_refund_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_normal_commodity_refund_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 列表
 */
export function exhibition_order_normal_commodity_cash_refund_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_normal_commodity_cash_refund_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function exhibition_order_record_refund_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_record_refund_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function exhibition_order_commodity_refund_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_commodity_refund_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function exhibition_order_commodity_cash_refund_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_commodity_cash_refund_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function exhibition_order_record_list (order_id) {
  return request({
    url: exhibition_orderApi.exhibition_order_record_list + order_id + '/record/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 列表
 */
export function exhibition_order_commodity_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_commodity_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function exhibition_order_normal_commodity_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_normal_commodity_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function exhibition_order_list (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function exhibition_order_create (parameter) {
  return request({
    url: exhibition_orderApi.exhibition_order_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function exhibition_order_update (parameter, exhibition_order_id) {
  return request({
    url: exhibition_orderApi.exhibition_order_update + exhibition_order_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function exhibition_order_delete (exhibition_order_id) {
  return request({
    url: exhibition_orderApi.exhibition_order_delete + exhibition_order_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
